<template>
  <div class="render">
    <div class="top">
      <h1>Детализация</h1>
    </div>
    <div class="my_toolbar">
      <v-tabs
        align-with-title
        v-model="tab"
      >
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <v-tab
          :key="item"
          active-class="my_active_tab"
          class="item_tab"
          v-for="item in tabs"
        >{{ item }}</v-tab>
      </v-tabs>
    </div>
    <div class="content_table">
      <v-tabs-items v-model="tab">
        <v-tab-item key="Детализация звонков">
          <FilterCustom
            :visible="{calls:all_calls,targets: all_targets, date:'today',phone:true, text:true, target: true}"
            @exportExcel="onExportExcelCalls"
            @updateFilter="onUpdateFilter"
          />
          <Table
            :callback_refresh="renderCalls"
            :data="table.calls.data"
            :headers="table.headers_call"
            :hideselect="true"
            :loading="loading_ivr"
            :pageCount_server="table.calls.pageCount_server"
            :perPage="perPage"
            :server="true"
            v-if="table.calls.data"
          />
          <div
            class="empty"
            v-else
          >
            <Icon name="add_leads" />
            <h2 class="subtitle">Запустите свой первый обзвон и получите лиды уже сегодня!</h2>
            <div
              @click="show_dialog=true"
              class="add"
            >
              <Icon name="plus" />Добавить первый обзвон
            </div>
            <CreateCall
              :callback_refresh="renderCalls"
              :data="call_data"
              :guid="call_guid"
              :show_dialog="show_dialog"
            />
          </div>
        </v-tab-item>
        <v-tab-item key="Детализация входящих">
          <FilterCustom
            :visible="{date:'today',phone:true, text:true}"
            @updateFilter="onUpdateFilter"
          />
          <Table
            :callback_refresh="renderIncoming"
            :data="table.incoming.data"
            :headers="table.headers_incoming"
            :hideselect="true"
            :loading="loading_incoming"
            :pageCount_server="table.incoming.pageCount_server"
            :perPage="perPage"
            :server="true"
            v-if="table.incoming.data"
          />
          <div
            class="empty"
            v-else
          >
            <Icon name="add_leads" />
            <h2 class="subtitle">Запустите свой первый обзвон и получите лиды уже сегодня!</h2>
            <div
              @click="show_dialog=true"
              class="add"
            >
              <Icon name="plus" />Добавить первый обзвон
            </div>
            <CreateCall
              :callback_refresh="renderCalls"
              :data="call_data"
              :guid="call_guid"
              :show_dialog="show_dialog"
            />
          </div>
        </v-tab-item>
        <v-tab-item key="Детализация SMS">
          <FilterCustom
            :visible="{calls:all_calls,date:'today',phone:true}"
            @updateFilter="onUpdateFilter"
          />
          <Table
            :callback_refresh="renderSms"
            :data="table.sms.data"
            :headers="table.headers_sms"
            :hideselect="true"
            :loading="loading_sms"
            :pageCount_server="table.sms.pageCount_server"
            :perPage="perPage"
            :server="true"
            v-if="table.sms.data"
          />
          <div
            class="empty"
            v-else
          >
            <Icon name="add_leads" />
            <h2 class="subtitle">Запустите свой первый обзвон и получите лиды уже сегодня!</h2>
            <div
              @click="show_dialog=true"
              class="add"
            >
              <Icon name="plus" />Добавить первый обзвон
            </div>
            <CreateCall
              :callback_refresh="renderSms"
              :data="call_data"
              :guid="call_guid"
              :show_dialog="show_dialog"
            />
          </div>
        </v-tab-item>
        <v-tab-item key="Общая статистика">
          <FilterCustom
            :visible="{calls:all_calls,date:'today'}"
            @updateFilter="onUpdateFilter"
          />
          <div class="blocks">
            <div class="block calls">
              <div class="big_block">
                <div class="left">
                  <Doughnut
                    :chart-data="chartCalls"
                    :chart-id="chartId"
                    :chart-options="chartOptions"
                    :css-classes="cssClasses"
                    :dataset-id-key="datasetIdKey"
                    :height="height"
                    :plugins="plugins"
                    :styles="styles"
                    :width="width"
                  />
                  <div class="num_big">
                    {{Math.round(ifHasValue(stat_data.time_calls))}}
                    <div>мин.</div>
                  </div>
                </div>
                <div class="right">
                  <div class="heading">Время разговоров</div>
                  <div class="robot">Робот говорил: {{Math.round(ifHasValue(stat_data.robot_say))}} мин.</div>
                  <div class="client">Клиент говорил: {{Math.round(ifHasValue(stat_data.client_say))}} мин.</div>
                  <div class="silence">Неловкое молчание: {{silence()}} мин.</div>
                </div>
              </div>
              <div class="small_blocks">
                <div class="block_item my_gray">
                  <div class="heading">Совершено звонков</div>
                  <div class="num">{{Math.round(ifHasValue(stat_data.calls_count))}}</div>
                </div>
                <div class="block_item my_gray">
                  <div class="heading">Время обзвона</div>
                  <div class="num">{{Math.round(ifHasValue(stat_data.time_calls))}} мин.</div>
                </div>
                <div class="block_item my_green">
                  <div class="heading">Количество дозвонов</div>
                  <div class="num">{{Math.round(ifHasValue(stat_data.calls_count)) - ifHasValue(stat_data.uncall)}}</div>
                </div>

                <div class="block_item my_green">
                  <div class="heading">Стоимость звонков</div>
                  <div class="num">{{Math.round(ifHasValue(stat_data.price_all))}}₽</div>
                </div>
                <div class="block_item my_red">
                  <div class="heading">Количество недозвонов</div>
                  <div class="num">{{ifHasValue(stat_data.uncall)}}</div>
                </div>
              </div>
            </div>
            <div class="block sms">
              <div class="big_block">
                <div class="left">
                  <Doughnut
                    :chart-data="chartSMS"
                    :chart-id="chartId"
                    :chart-options="chartOptions"
                    :css-classes="cssClasses"
                    :dataset-id-key="datasetIdKey"
                    :height="height"
                    :plugins="plugins"
                    :styles="styles"
                    :width="width"
                  />
                  <div class="num_big">
                    {{Math.round(ifHasValue(stat_data.sms_count))}}
                    <div>SMS</div>
                  </div>
                </div>
                <div class="right">
                  <div class="heading">Количество SMS</div>
                  <div class="robot">Доставлено: {{Math.round(ifHasValue(stat_data.success))}} шт.</div>
                  <div class="client">Не доставлено: {{Math.round(ifHasValue(stat_data.sms_count - stat_data.success))}} шт.</div>
                </div>
              </div>
              <div class="small_blocks">
                <div class="block_item my_gray">
                  <div class="heading">СМС доставлено</div>
                  <div class="num">{{Math.round(ifHasValue(stat_data.success))}}</div>
                </div>
                <div class="block_item my_gray">
                  <div class="heading">Количество СМС</div>
                  <div class="num">{{Math.round(ifHasValue(stat_data.sms_count))}}</div>
                </div>
                <div class="block_item my_red">
                  <div class="heading">СМС не доставлено</div>
                  <div class="num">{{calc_percent()}}%</div>
                </div>
                <div class="block_item my_green">
                  <div class="heading">Стоимость смс</div>
                  <div class="num">{{Math.round(ifHasValue(stat_data.sum_price))}}₽</div>
                </div>
              </div>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>
import CreateCall from '../components/Modal/Calls/CreateCall.vue'
import Table from '../components/Table/DataTable.vue'
import Icon from '../components/SetIcon.vue'
import FilterCustom from '../components/FilterMy.vue'
import { Doughnut } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(ArcElement, CategoryScale)
export default {
  components: {
    Table, CreateCall, Icon,
    Doughnut, FilterCustom
  },
  props: {
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 178
    },
    height: {
      type: Number,
      default: 178
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading_ivr: true,
      loading_sms: true,
      loading_incoming: true,
      repage: false,
      all_calls: [],
      all_targets: [],
      perPage: 10,
      filters_component_ivr: false,
      filters_component_incoming: false,
      filters_component_sms: false,
      filters_component_detail: false,
      selects_load: [],
      paging_ivr: {},
      paging_incoming: {},
      paging_sms: {},
      stat_data: {
        calls_count: 0,
        sms_count: 0,
        time_calls: 0,
        client_say: 0,
        robot_say: 0,
        silence: 0,
        uncall: 0,
        price_all: 0,
        success: 0,
        sum_price: 0,
        undelivered: 0
      },
      show_dialog: false,
      chartCalls: {
        datasets: [
          {
            backgroundColor: [ '#FAA373', '#57B6ED', '#9A99FB' ],
            data: [ 0, 0, 0 ],
            borderWidth: 0
          }
        ]
      },
      chartSMS: {
        datasets: [
          {
            backgroundColor: [ '#FAA373', '#57B6ED' ],
            data: [ 0, 0 ],
            borderWidth: 0
          }
        ]
      },
      chartOptions: {
        responsive: true,
        backgroundColor: '#E8F7F4',
        maintainAspectRatio: false,
        cutout: 65
      },
      tabs: [ 'Детализация звонков', 'Детализация входящих', 'Детализация SMS', 'Общая статистика' ],
      tab: 'Детализация звонков',
      table: {
        calls: {
          data: [],
          pageCount_server: 0,
        },
        sms: {
          data: [],
          pageCount_server: 0,
        },
        incoming: {
          data: [],
          pageCount_server: 0,
        },
        headers_call: [
          { text: '№', align: 'start', value: 'n' },
          { text: 'Номер', value: 'phoneB' },
          { text: 'Аудиозапись', value: 'record' },
          { text: 'Клиент сказал', value: 'text' },
          { text: 'Цели', value: 'target' },
          { text: 'Статус', value: 'status' },
          /* { text: 'Исходящий номер', value: 'phoneA' }, */
          { text: 'Дата обзвона', value: 'created_at' }
        ],
        headers_incoming: [
          { text: '№', align: 'start', value: 'n' },
          { text: 'Входящий номер', value: 'phoneA' },
          { text: 'На номер', value: 'phoneB' },
          { text: 'Аудиозапись', value: 'record' },
          { text: 'Клиент сказал', value: 'text' },
          { text: 'Дата обзвона', value: 'created_at' }
        ],
        headers_sms: [
          { text: '№', align: 'start', value: 'n' },
          { text: 'Номер', value: 'phone' },
          { text: 'Текст', value: 'text' },
          // { text: 'Альфа-имя', value: 'alpha' },
          // { text: 'Цена', value: 'price' },
          // { text: 'Статус', value: 'status' },
          { text: 'Дата отправки', value: 'created_at' }
        ]
      }
    }
  },
  mounted () {
    this.listCalls()
    this.listTarget()
  },
  methods: {
    async onExportExcelCalls (filters) {
      
      if (filters) {
        this.loading_ivr = true
        // eslint-disable-next-line no-unused-vars
        alert('Отчет начал формироваться')
        let user = await this.$store.getters[ 'user/getUserFields' ] // Получение данных о пользователе
        const login = user.login // Получение имени пользователя
        if (login === '79299762631') {
          await this.$http.download("/analytics/export-excel-calls-123FIN", {filters: filters,user: login}) 
        }else if (login === '79655188844') {
    await this.$http.download("/analytics/export-excel-calls-79655188844", {filters: filters, user: login});
  }
        else
          await this.$http.download("/analytics/export-excel-calls", {filters: filters, user: login})


        this.loading_ivr = false
        alert('Файл загружен')
      }
    },
    calc_percent () {
      if (this.stat_data.undelivered && this.stat_data.sms_count) return 100 - (this.stat_data.undelivered / this.stat_data.sms_count * 100).toFixed(2)
      return 0
    },
    silence () {
      let min = Math.round(this.stat_data.client_say * 0.02)
      if (min) return min
      return 0
    },
    onUpdateFilter (new_filter) {
      if (this.tab == 0) {
        this.filters_component_ivr = new_filter
      }
      if (this.tab == 1) {
        this.filters_component_incoming = new_filter
      }
      if (this.tab == 2) {
        this.filters_component_sms = new_filter
      }
      if (this.tab == 3) {
        this.filters_component_detail = new_filter
      }
      this.repage = true
      this.refreshPage()

    },

    refreshPage () {
      if (this.tab == 0) this.renderCalls()
      if (this.tab == 1) this.renderIncoming()
      if (this.tab == 2) this.renderSms()
      if (this.tab == 3) this.renderDetail()
    },
    getFilter (paging, filters = false) {

      let component = {}

      if (paging == 'ivr') {
        if (filters)
          this.paging_ivr = filters
        else
          filters = this.paging_ivr
        component = this.filters_component_ivr
      }
      if (paging == 'incoming') {
        if (filters)
          this.paging_incoming = filters
        else
          filters = this.paging_incoming
        component = this.filters_component_incoming
      }
      if (paging == 'sms') {
        if (filters)
          this.paging_sms = filters
        else
          filters = this.paging_sms
        component = this.filters_component_sms
      }
      if (paging == 'detail') {
        filters = { sortBy: true }
        component = this.filters_component_detail
      }
      if (!filters.sortBy) return false
      if (this.repage) {
        filters.page = 1
        this.repage = false
      }
      filters.perPage = this.perPage
      let filters_post = JSON.parse(JSON.stringify(filters))
      for (let key in component) {
        if (component[ key ]) {
          filters_post[ key ] = component[ key ]
        }
      }

      return filters_post
    },
    listCalls () {
      let _this = this
      this.$http.post("/call/list",
        {

        }, (data) => {
          _this.all_calls.push("Тесты")
          data.forEach((item) => {
            _this.all_calls.push(item.name)
          })
        })
    },

    async listTarget () {
      const _this = this
      this.$http.post("/reports/list-targets",
        {}, (data) => {
          if (data && data.length) {
            data.forEach((item) => {
              _this.all_targets.push(item.Target_Name)
            })
          }
        })
    },

    renderIncoming (filters = false) {
      let _this = this
      let filters_post = this.getFilter("incoming", filters)

      if (filters_post) {
        _this.loading_incoming = true
        this.$http.post("/analytics/incoming", {
          filters: filters_post
        }, data => {
          
          this.loading_incoming = false
          if (data.count) this.table.incoming.pageCount_server = data.count
          this.table.incoming.data = data.data
          if (!_this.selects_load.length) {
            let my_data = []
            data.data.forEach(item => {
              for (let i in item) {
                if (i == 'call_name') my_data.push(item.call_name)
                _this.selects_load = my_data
              }
            })
          }
        })
      }
    },
    renderCalls (filters = false) {
      let _this = this
      let filters_post = this.getFilter("ivr", filters)


      if (filters_post) {
        _this.loading_ivr = true
        this.$http.post("/analytics/calls", {
          filters: filters_post
        }, data => {
         
          this.loading_ivr = false
          if (data.count) this.table.calls.pageCount_server = data.count
          this.table.calls.data = data.data
          if (!_this.selects_load.length) {
            let my_data = []
            data.data.forEach(item => {
              for (let i in item) {
                if (i == 'call_name') my_data.push(item.call_name)
                _this.selects_load = my_data
              }
            })
          }
        })
      }
    },
    renderSms (filters = false) {
      let _this = this
      let filters_post = this.getFilter("sms", filters)
      if (filters_post) {
        _this.loading_sms = true
        this.$http.post("/analytics/sms", {
          filters: filters_post
        }, data => {
          this.loading_sms = false
          if (data.count) this.table.sms.pageCount_server = data.count
          this.table.sms.data = data.data
          if (!_this.selects_load.length) {
            let my_data = []
            data.data.forEach(item => {
              for (let i in item) {
                if (i == 'call_name') my_data.push(item.call_name)
                _this.selects_load = my_data
              }
            })
          }
        })
      }
    },
    today () {
      return (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    },
    ifHasValue (value) {
      if (value) return value
      return 0
    },
    updateDetail (data) {
      let _this = this
      let my_data = {
        selects_load: []
      }
      if (data.sms && data.sms.length) {
        data.sms.forEach(item => {
          for (let i in item) {
            if (i != 'call_name') {
              if (!my_data[ i ]) my_data[ i ] = Number(item[ i ])
              else my_data[ i ] += Number(item[ i ])
            }
          }
        })
      }
      if (data.calls && data.calls.length) {
        data.calls.forEach(item => {
          for (let i in item) {
            if (i != 'call_name') {
              if (!my_data[ i ]) my_data[ i ] = Number(item[ i ])
              else my_data[ i ] += Number(item[ i ])
            } else my_data.selects_load.push(item.call_name)
            if (!_this.selects_load.length) _this.selects_load = my_data.selects_load
          }
        })
      }
      _this.stat_data = my_data
      let calls = []
      let sms = []
      calls.push(my_data.robot_say)
      calls.push(my_data.client_say)
      calls.push(_this.silence())
      sms.push(my_data.success)
      sms.push(my_data.undelivered)
      _this.chartCalls.datasets[ 0 ].data = calls
      _this.chartSMS.datasets[ 0 ].data = sms
    },
    renderDetail () {
      let _this = this
      let filters_post = this.getFilter("detail")

      if (filters_post) {
        _this.$http.post("/analytics/detail", filters_post, data => {
          let my_data = {
            selects_load: []
          }

          if (data.sms.length) {
            data.sms.forEach(item => {
              for (let i in item) {
                if (i != 'call_name') {
                  if (!my_data[ i ]) my_data[ i ] = Number(item[ i ])
                  else my_data[ i ] += Number(item[ i ])
                }
              }
            })
          }
          if (data.calls.length) {
            data.calls.forEach(item => {
              for (let i in item) {
                if (i != 'call_name') {
                  if (!my_data[ i ]) my_data[ i ] = Number(item[ i ])
                  else my_data[ i ] += Number(item[ i ])
                } else my_data.selects_load.push(item.call_name)
                //    if(!_this.selects_load.length) _this.selects_load = my_data.selects_load
              }
            })
          }
          _this.stat_data = my_data
          _this.loading = false
          let calls = []
          let sms = []
          calls.push(my_data.robot_say)
          calls.push(my_data.client_say)
          calls.push(_this.silence())
          if (my_data.success) sms.push(my_data.success)
          else sms.push(0)
          if (my_data.undelivered) sms.push(my_data.undelivered)
          else sms.push(0)
          _this.chartCalls.datasets[ 0 ].data = calls
          _this.chartSMS.datasets[ 0 ].data = sms
        })
      }
    }
  }
}
</script>
<style scoped>
  .content_table {
    margin-top: 24px;
  }
  .blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .add {
    border: none;
    padding: 12px 20px 12px 22px;
    background: #fee600 !important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #2b2d33;
    cursor: pointer;
    display: flex;
    margin-top: 24px;
    justify-content: center;
  }
  .add .icon {
    margin-right: 11px;
  }
  .item_tab {
    text-transform: none !important;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 120%;
    color: #808185;
  }
  .my_active_tab {
    color: #2b2d33;
  }

  .my_toolbar {
    margin-top: 16px;
  }
  .num_big {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    color: #404247;
    max-width: 75px;
    position: absolute;
    top: 55px;
    width: 75px;
    left: 51px;
  }
  .block_item {
    padding: 16px 24px;
    border-radius: 8px;

    width: 265px;
    margin-bottom: 36px;
  }
  .block_item:nth-child(odd) {
    margin-right: 20px;
  }
  .small_blocks {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    /*justify-content: center;*/
  }
  .block_item .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 120%;
    color: #404247;
    margin-bottom: 12px;
    text-align: left;
  }
  .block_item .num {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 130%;
    color: #404247;
    text-align: left;
  }
  .my_red .num {
    color: #ee505a;
  }
  .my_gray {
    background: #f8f8f8;
  }
  .my_red {
    background: #fdeeee;
  }
  .my_green {
    background: #e8f7f4;
  }
  .block {
    display: flex;
    flex-wrap: wrap;
    padding: 32px 24px;
    justify-content: center;
    max-width: 600px;
    height: min-content;
  }
  .big_block {
    background: #e8f7f4;
    border-radius: 8px;
    display: flex;
    padding: 24px 16px;
    min-width: 500px;
    max-height: 230px;
  }
  .right {
    margin-left: 32px;
  }
  .heading {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    text-align: left;
    line-height: 130%;
    color: #404247;
  }
  .robot,
  .client,
  .silence {
    text-align: left;
    position: relative;
    margin-top: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    padding-left: 25px;
    line-height: 120%;
    color: #404247;
  }
  .robot:before {
    display: block;
    content: "";
    width: 18px;
    height: 18px;
    background: #faa373;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .client:before {
    display: block;
    content: "";
    width: 18px;
    height: 18px;
    background: #57b6ed;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .silence:before {
    display: block;
    content: "";
    width: 18px;
    height: 18px;
    background: #9a99fb;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .content {
    height: 100%;
    display: flex;
    position: relative;
    z-index: 1000;
  }
  .empty {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 500px;
  }
  .subtitle {
    max-width: 450px;
    margin-bottom: 32px;
  }
  .left {
    position: relative;
  }
  @media (max-width: 1000px) {
    .filters {
      flex-direction: column;
    }
  }
  @media (max-width: 650px) {
    .top {
      justify-content: center;
    }
    .big_block {
      flex-direction: column;
      max-height: initial;
      min-width: initial;
    }
    .num_big {
      left: calc(50% - 36px);
    }
    .right {
      margin-left: 0;
    }
  }
  @media (max-width: 620px) {
    .block_item:nth-child(odd) {
      margin-right: 0;
    }
    .small_blocks {
      flex-direction: column;
    }
  }
  @media (max-width: 400px) {
    h1 {
      margin-top: 15px;
    }
  }
</style>
