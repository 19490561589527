import Vue from "vue";
import VueRouter from "vue-router";
import Bases from "../views/BasesView";
import Scipts from "../views/ScrciptsView";
import Analytics from "../views/AnalyticsView";
import Calls from "../views/CallsView";
import Integrations from "../views/IntegrationsView";
import Help from "../views/HelpView";
import Cabinet from "../views/CabinetView";
import UserSettings from "../views/UserSettings";
import Users from "../views/admin/UsersAdminView";
import UsersPays from "../views/admin/PaymentsAdminView";
import Words from "../views/admin/WordsAdminView";
import BalckList from "../views/admin/BlackListAdminView";
import ExtraServices from "@/views/admin/ExtraServicesAdminView";
import TariffsMinutes from "@/views/admin/TariffsAdminView";
import Notifications from "@/views/admin/NotificationsView"
import MonitoringCall from "@/views/admin/MonitoringCall"
import Report from "@/views/ReportView";
import IncomingCalls from "@/views/IncomingCalls";
import StatisticsCRM from "@/views/admin/Statistics"
import CarouselNumbersAdmin from "@/views/admin/CarouselNumbersAdmin"

Vue.use(VueRouter);

const routes = [
  {
    path: "/bases",
    name: "bases",
    component: Bases,
  },
  {
    path: "/bases/:id",
    name: "base",
    component: Bases,
  },
  {
    path: "/scripts",
    name: "scripts",
    component: Scipts,
  },
  {
    path: "/analytics",
    name: "analytics",
    component: Analytics,
  },
  {
    path: "/report",
    name: "report",
    component: Report,
  },
  {
    path: "/calls",
    name: "calls",
    component: Calls,
  },
  {
    path: "/incoming_calls",
    name: "IncomingCalls",
    component: IncomingCalls,
  },
  {
    path: "/integrations",
    name: "integrations",
    component: Integrations,
  },
  {
    path: "/help",
    name: "help",
    component: Help,
  },
  {
    path: "/",
    name: "cabinet",
    component: Cabinet,
  },
  {
    path: "/user_settings",
    name: "user_settings",
    component: UserSettings,
  },
  {
    path: "/users",
    name: "users",
    component: Users,
  },
  {
    path: "/users_payments",
    name: "users_pays",
    component: UsersPays,
  },
  {
    path: "/words",
    name: "words",
    component: Words,
  },
  {
    path: "/blacklist",
    name: "blacklist",
    component: BalckList,
  },
  {
    path: "/extra_services",
    name: "extra_services",
    component: ExtraServices,
  },
  {
    path: "/tariff_minutes",
    name: "tariff_minutes",
    component: TariffsMinutes,
  },
  {
    path: "/notifications",
    name: "notificationss",
    component: Notifications,
  },
  {
    path: "/monitoring_calls",
    name: "monitoring_calls",
    component: MonitoringCall,
  },
  {
    path: "/statistics",
    name: "/statistics",
    component: StatisticsCRM,
  },
  {
    path: "/CarouselNumbersAdmin",
    name: "/CarouselNumbersAdmin",
    component: CarouselNumbersAdmin,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
