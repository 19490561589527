<template>
  <div>
    <div 
      v-if="!$store.getters['user/getUserFields'].is_admin" 
      class="text-center my-5 pa-5"
    >
      <h3>Вы не администратор!</h3>
      <p>Авторизируйтесь как администратор, чтобы просматривать этот раздел.</p>
    </div>

    <v-container v-else fluid>
      <v-card class="mb-5 elevation-2">
  <v-card-title>
    <div class="filters-header">
      <h2 class="text-h6 mb-0">Фильтры</h2>
      <v-btn
        text
        color="secondary"
        class="ml-3"
        small
        @click="resetFilters"
      >
        <v-icon left>mdi-refresh</v-icon>
        Сбросить
      </v-btn>
    </div>
  </v-card-title>
  <v-divider></v-divider>
  
  <v-card-text>
    <v-row dense>
      <v-col cols="12" md="6">
        <v-row dense>
          <v-col cols="12" sm="6">
            <label class="filter-label">Звонок с</label>
            <date-picker
              v-model="dateRange.start"
              type="datetime"
              format="DD.MM.YYYY HH:mm"
              valueType="format"
              :editable="true"
              :time-picker-options="{
                start: '00:00', 
                step: '00:01',
                end: '23:59', 
                format: 'HH:mm'
              }"
              input-class="my-date-input"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <label class="filter-label">Звонок по</label>
            <date-picker
              v-model="dateRange.end"
              type="datetime"
              format="DD.MM.YYYY HH:mm"
              valueType="format"
              :editable="true"
              :time-picker-options="{
                start: '00:00', 
                step: '00:01',
                end: '23:59', 
                format: 'HH:mm'
              }"
              input-class="my-date-input"
            />
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="6">
        <v-row dense>
          <v-col cols="12" sm="6">
            <label class="filter-label">Заливка с</label>
            <date-picker
              v-model="uploadDateRange.start"
              type="datetime"
              format="DD.MM.YYYY HH:mm"
              valueType="format"
              :editable="true"
              :time-picker-options="{
                start: '00:00', 
                step: '00:01',
                end: '23:59', 
                format: 'HH:mm'
              }"
              input-class="my-date-input"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <label class="filter-label">Заливка по</label>
            <date-picker
              v-model="uploadDateRange.end"
              type="datetime"
              format="DD.MM.YYYY HH:mm"
              valueType="format"
              :editable="true"
              :time-picker-options="{
                start: '00:00', 
                step: '00:01',
                end: '23:59', 
                format: 'HH:mm'
              }"
              input-class="my-date-input"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense class="mt-3">
      <v-col cols="12" sm="6" md="2" class="same-height">
        <v-select
  v-model="selectedTrunk"
  :items="trunkOptions"
  item-text="text"
  item-value="value"
  label="Транк"
  multiple
  outlined
  dense
  hide-details="auto"
/>
      </v-col>

      <v-col cols="12" sm="6" md="2" class="same-height">
        <v-select
          v-model="selectedPool"
          :items="poolOptions"
          item-text="text"
          item-value="value"
          label="Пул"
          multiple
          outlined
          dense
          hide-details="auto"
        />
      </v-col>

      <v-col cols="12" sm="6" md="2">
        <v-text-field
        class="my-override"
          v-model="callsRange.from"
          type="number"
          min="0"
          label="Звонков (от)"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>
      
      <v-col cols="12" sm="6" md="2">
        <v-text-field
        class="my-override"
          v-model="callsRange.to"
          type="number"
          min="0"
          label="Звонков (до)"
          outlined
          dense
          hide-details="auto"
        />
      </v-col>

      <v-col cols="12" sm="6" md="3" class="same-height">
        <v-autocomplete
          v-model="selectedFileNames"
          :items="fileNameOptions"
          item-text="text"
          item-value="value"
          label="Название файла"
          multiple
          chips
          clearable
          outlined
          dense
          autocomplete
          tags
          hide-details="auto"
        />
      </v-col>

      <v-col cols="12" sm="6" md="1" class="d-flex align-end">
        <v-btn
          color="primary"
          @click="applyFilters"
          block
          elevation="2"
          small
        >
          <v-icon left>mdi-filter</v-icon>
          Применить
        </v-btn>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>

      <v-card class="elevation-2">
        <v-card-title class="justify-space-between align-center">
          <div class="d-flex align-center">
            <h2 class="text-h6 mb-0 mr-5">Список номеров</h2>
            <v-chip v-if="selectedRows.length" color="info" text-color="white" small>
              {{ selectedRows.length }} выбрано
            </v-chip>
          </div>
          <div>
            <v-btn
  text
  small
  color="primary"
  class="mr-3"
  @click="toggleSelectAll"
>
  <v-icon left>mdi-check-all</v-icon>
  {{ selectAllMode ? "Отменить выбор всех" : "Выбрать все" }}
</v-btn>
            <v-btn
  v-if="showMoveSelectedButton"
  text
  small
  color="primary"
  class="mr-3"
  @click="moveSelectedDialogOpen = true"
>
  <v-icon left>mdi-transfer</v-icon>
  Переместить выбранные
</v-btn>
            <v-btn text small color="primary" class="mr-3" @click="uploadDialogOpen = true">
              <v-icon left>mdi-plus</v-icon>
              Добавить номера
            </v-btn>

            <v-btn text small color="primary" class="mr-3" @click="downloadexcel"     :loading="excelLoading"
            :disabled="excelLoading">
              <v-icon left>mdi-download</v-icon>
              Скачать Excel
            </v-btn>

            <v-btn text small color="primary" @click="moveReserveDialogOpen = true">
              <v-icon left>mdi-transfer</v-icon>
              Перемещение из резерва
            </v-btn>
          </div>
        </v-card-title>
        
        <v-divider></v-divider>

        <v-data-table
          :headers="headers"
          :items="tableItems"
          item-key="id"
          show-select
          v-model="selectedRows"

          :options="options"
          @update:options="onUpdateOptions"
          :server-items-length="totalItems"

          class="carousel-numbers-table"
          :loading="tableLoading"

          :footer-props="{
            itemsPerPageOptions: [100],
            showCurrentPage: true,
            showFirstLastPage: true
          }"
        >
          <template v-slot:loading>
            <v-sheet color="transparent" class="text-center pa-3">
              <v-progress-circular
                color="primary"
                size="32"
                indeterminate
              />
              <div>Загрузка данных...</div>
            </v-sheet>
          </template>

          <template v-slot:[`item.number`]="{ item }">
            {{ item.number }}
          </template>
          <template v-slot:[`item.trunk`]="{ item }">
            {{ item.trunk }}
          </template>
          <template v-slot:[`item.pool`]="{ item }">
            {{ item.pool }}
          </template>
          <template v-slot:[`item.callsMade`]="{ item }">
            {{ item.callsMade }}
          </template>
          <template v-slot:[`item.successPercent`]="{ item }">
            {{ item.successPercent }}%
          </template>
          <template v-slot:[`item.minRotationTime`]="{ item }">
            {{ item.minRotationTime }}
          </template>
          <template v-slot:[`item.lastCall`]="{ item }">
            {{ item.lastCall }}
          </template>
          <template v-slot:[`item.fileName`]="{ item }">
            {{ item.fileName }}
          </template>
          <template v-slot:[`item.uploadDate`]="{ item }">
            {{ item.uploadDate }}
          </template>

          <template v-slot:no-data>
            <v-alert type="info" elevation="0">
              Нет данных для отображения.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-dialog v-model="uploadDialogOpen" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h6">Залить номера из Excel</span>
        </v-card-title>
        <v-card-text>
          <v-file-input
            v-model="uploadFile"
            label="Выберите Excel-файл"
            accept=".xls, .xlsx"
            prepend-icon="mdi-file-excel"
            outlined
            dense
          />

          <v-select
            v-model="uploadTrunk"
            :items="trunkOptions"
            item-text="text"
            item-value="value"
            label="Выберите транк"
            outlined
            dense
          />

          <v-select
            v-model="uploadPool"
            :items="uploadPoolOptions"
            label="Выберите пул"
            outlined
            dense
          />

          <v-text-field
            v-model="uploadTag"
            label="Тег (необязательно)"
            prepend-icon="mdi-tag"
            outlined
            dense
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="secondary" @click="uploadDialogOpen = false">
            Отмена
          </v-btn>
          <v-btn color="primary" @click="uploadExcel">
            Залить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="moveSelectedDialogOpen" max-width="500px">
  <v-card>
    <v-card-title>
      <span class="text-h6">Переместить выбранные номера</span>
    </v-card-title>
    <v-card-text>
      <v-select
        v-model="moveSelectedPool"
        :items="uploadPoolOptions" 
        label="Выберите пул"
        outlined
        dense
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn text color="secondary" @click="moveSelectedDialogOpen = false">
        Отмена
      </v-btn>
      <v-btn color="primary" :disabled="loadingMoveSelected" @click="moveSelectedNumbers">
        Переместить
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>

    <v-dialog v-model="moveReserveDialogOpen" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="text-h6">Перемещение из резерва</span>
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="moveReserveTrunk"
            :items="trunkOptions"
            item-text="text"
            item-value="value"
            label="Выберите транк"
            outlined
            dense
          />
          <v-text-field
            v-model="moveReserveCount"
            label="Количество номеров"
            type="number"
            outlined
            dense
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text color="secondary" @click="moveReserveDialogOpen = false">
            Отмена
          </v-btn>
          <v-btn color="primary" :disabled="loadingReserveMove" @click="moveFromReservePool">
            Переместить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  name: "CarouselNumbersAdmin",
  components: {
    DatePicker,
  },
  data() {
    return {
      selectAllMode: false,
      uploadDialogOpen: false,    
      uploadFile: null,           
      uploadTrunk: '',            
      uploadPool: '',             
      uploadTag: '',              
      excelLoading: false,
      uploadDateRange: {
        start: "",
        end: "",
      },
      selectedFileNames: [],
      uploadPoolOptions: [
        "main",
        "reserve_pool",
        "util"
      ],

      moveReserveDialogOpen: false,
      moveReserveTrunk: "",
      moveReserveCount: "",
      loadingReserveMove: false,
      moveSelectedDialogOpen: false,
    moveSelectedPool: "",       
    loadingMoveSelected: false, 

      options: {
        page: 1,
        itemsPerPage: 100,
        sortBy: [],
        sortDesc: []
      },
      totalItems: 0,

      dateRange: {
        start: "25.11.2024 00:00",
        end: "",
      },
      timePickerOptions: {
        start: '00:00',
        step: '00:01',
        end: '23:30',
        format: 'HH:mm',
      },

      trunkOptions: [],
      selectedTrunk: [],
      poolOptions: [
        { text: "main", value: "main" },
        { text: "reserve_pool", value: "reserve_pool" },
        { text: "util", value: "util" },
      ],
      selectedPool: [],

      callsRange: {
        from: "",
        to: "",
      },
      fileNameOptions: [],
      tableLoading: false,
      headers: [
        {
          text: "",
          value: "data-table-select",
          sortable: false,
          align: "center",
          width: "10px"
        },
        {
          text: "Номер",
          value: "number",
          sortable: false
        },
        {
          text: "Транк",
          value: "trunk",
          sortable: false
        },
        {
          text: "Пул",
          value: "pool",
          sortable: false
        },
        {
          text: "Звонков сделано",
          value: "callsMade",
          sortable: true
        },
        {
          text: "Процент дозвона",
          value: "successPercent",
          sortable: true
        },
        {
          text: "Мин. время ротации",
          value: "minRotationTime",
          sortable: true
        },
        {
          text: "Время последнего звонка",
          value: "lastCall",
          sortable: true
        },
        {
          text: "Название файла",
          value: "fileName",
          sortable: false
        },
        {
          text: "Дата и время заливки",
          value: "uploadDate",
          sortable: false
        },
      ],
      tableItems: [],
      selectedRows: [],
    };
  },
  created() {
  this.loadTrunks().then(() => {
    this.dateRange.start = "25.11.2024 00:00";
    this.dateRange.end = this.getCurrentMoscowTime();

    this.loadFileNameOptions();
    this.applyFilters();
  });
},
computed: {
  showMoveSelectedButton() {
    const trunkSelected = this.selectedTrunk.length === 1;

    const rowsOrAll = (this.selectedRows.length > 0) || this.selectAllMode;

    return trunkSelected && rowsOrAll;
  }
},
  methods: {
    toggleSelectAll() {
  this.selectAllMode = !this.selectAllMode;
  if (this.selectAllMode) {
    this.selectedRows = [];
    alert("Теперь при перемещении будут выбраны все номера по текущему фильтру.");
  } else {
    console.log("Куку")
  }
},
moveFromReservePool() {
  if (!this.moveReserveTrunk) {
    alert("Пожалуйста, выберите транк.");
    return;
  }
  if (!this.moveReserveCount || this.moveReserveCount <= 0) {
    alert("Пожалуйста, введите корректное количество номеров.");
    return;
  }

  this.loadingReserveMove = true;

  this.$http.post('/statistics/move-reserve-pool', {
    trunk: this.moveReserveTrunk,
    count: Number(this.moveReserveCount)
  }, (resp) => {
    if (resp === "error") {
      alert("Ошибка при перемещении!");
    } else {
      
      if (resp.details && Array.isArray(resp.details)) {
        
        const messages = resp.details
          .map(detail => detail.data && detail.data.message)
          .filter(message => message)
          .join('\n'); 
        if (messages) {
          alert(messages);
        } else {
          alert(resp.message || "Номера успешно перемещены!");
        }
      } else {
        alert(resp.message || "Номера успешно перемещены!");
      }

      this.moveReserveDialogOpen = false;
      this.moveReserveTrunk = "";
      this.moveReserveCount = "";

      this.applyFilters();
    }
    this.loadingReserveMove = false;
  });
},

    onUpdateOptions(newOptions) {
      this.options = newOptions;
      this.applyFilters();
    },

    async loadTrunks() {
      try {
        const response = await this.$http.get('/statistics/get-trunks');
        const trunkPoolLengths = response.data.trunk_pool_lengths;
        if (!trunkPoolLengths) {
          console.warn('Не удалось найти trunk_pool_lengths в ответе:', response);
          return;
        }
        this.trunkOptions = Object.keys(trunkPoolLengths).map(key => ({
          text: key,
          value: key
        }));
      } catch (error) {
        console.error('Ошибка при загрузке транков:', error);
      }
    },

    async loadFileNameOptions() {
      try {
        const response = await this.$http.get('/statistics/get-file-tags');
        this.fileNameOptions = response.data.map(tag => ({
          text: tag,
          value: tag
        }));
      } catch (error) {
        console.error('Ошибка при загрузке названий файлов:', error);
      }
    },
    moveSelectedNumbers() {
  if (!this.moveSelectedPool) {
    alert("Пожалуйста, выберите пул.");
    return;
  }
  if (this.selectedTrunk.length === 0) {
    alert("Не выбран ни один транк в фильтре!");
    return;
  }

  this.loadingMoveSelected = true;

  if (this.selectAllMode) {
    const payload = {
      dateRange: this.dateRange,
      uploadDateRange: this.uploadDateRange,
      selectedTrunk: this.selectedTrunk,
      selectedPool: this.selectedPool,
      callsRange: this.callsRange,
      selectedFileNames: this.selectedFileNames,
      sortBy: this.options.sortBy,
      sortDesc: this.options.sortDesc,
      newPool: this.moveSelectedPool
    };

    this.$http.post('/statistics/move-selected-all', payload, (resp) => {
      if (resp === "error") {
        alert("Ошибка при перемещении!");
      } else {
        const firstMessage = resp.details && resp.details[0] && resp.details[0].data && resp.details[0].data.message
          ? resp.details[0].data.message
          : "Номера успешно перемещены!";
        alert(firstMessage);
        this.moveSelectedDialogOpen = false;
        this.moveSelectedPool = "";
        this.applyFilters();
      }
      this.loadingMoveSelected = false;
    });
  } else {
    if (this.selectedRows.length === 0) {
      alert("Не выбрано ни одного номера!");
      this.loadingMoveSelected = false;
      return;
    }

    const phoneNumbers = this.selectedRows.map(row => row.number);
    const trunksToSend = this.selectedTrunk;

    const payload = {
      trunks: trunksToSend,
      phoneNumbers,
      newPool: this.moveSelectedPool
    };

    this.$http.post('/statistics/move-selected', payload, (resp) => {
      if (resp === "error") {
        alert("Ошибка при перемещении!");
      } else {
        if (resp.details && Array.isArray(resp.details)) {
          const messages = resp.details
            .map(detail => detail.data && detail.data.message)
            .filter(message => message)
            .join('\n');
          if (messages) {
            alert(messages);
          } else {
            alert("Номера успешно перемещены!");
          }
        } else {
          alert(resp.message || "Номера успешно перемещены!");
        }
        this.moveSelectedDialogOpen = false;
        this.moveSelectedPool = "";
        this.applyFilters();
      }
      this.loadingMoveSelected = false;
    });
  }
},

    applyFilters() {

      this.tableLoading = true;
      const payload = {
        dateRange: this.dateRange,
        uploadDateRange: this.uploadDateRange,
        selectedTrunk: this.selectedTrunk,
        selectedPool: this.selectedPool,
        callsRange: this.callsRange,
        selectedFileNames: this.selectedFileNames,
        page: this.options.page,
        itemsPerPage: this.options.itemsPerPage,
        sortBy: this.options.sortBy,
        sortDesc: this.options.sortDesc
      };

      this.$http.post('/statistics/carousel', payload,
        (response) => {
          if (!response.result) {
            console.warn("Некорректный формат ответа:", response);
            this.tableLoading = false;
            return;
          }

          const dataArr = response.result.data;
          const total = response.result.total;
          this.tableItems = dataArr.map((item, index) => ({
            id: (this.options.page - 1)*this.options.itemsPerPage + index+1,
            number: item.phoneA || '',
            trunk: item.channel || '',
            pool: item.pool || 'не найден',
            callsMade: item.total_calls || 0,
            successPercent: item.percentage_calls_over_3 || 0,
            minRotationTime: item.min_rotation_time != null ? item.min_rotation_time : '',
            fileName:item.fileName || '',
            uploadDate: item.uploadDate || '',
            lastCall: item.last_call || ''
          }));

          this.totalItems = total;
          this.tableLoading = false;
        },
        (error) => {
          console.error("Ошибка при загрузке данных:", error);
          this.tableLoading = false;
        }
      );
    },

    getCurrentMoscowTime() {
      const now = new Date();
      const utc = now.getTime() + now.getTimezoneOffset() * 60000;
      const moscowOffset = 3 * 60 * 60000;
      const moscowTime = new Date(utc + moscowOffset);
      const pad = (n) => (n < 10 ? '0' + n : n);
      const day = pad(moscowTime.getDate());
      const month = pad(moscowTime.getMonth()+1);
      const year = moscowTime.getFullYear();
      const hours = pad(moscowTime.getHours());
      const minutes = pad(moscowTime.getMinutes());
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    },

    resetFilters() {
  const defaultStartDate = "25.11.2024 00:00";
  const currentDate = this.getCurrentMoscowTime();

  this.dateRange.start = defaultStartDate;
  this.dateRange.end   = currentDate;

  this.uploadDateRange.start = "";
  this.uploadDateRange.end   = "";

  this.selectedTrunk   = [];
  this.selectedPool    = [];
  this.callsRange.from = "";
  this.callsRange.to   = "";
  this.selectedFileNames = [];
  this.options.page = 1;

  this.applyFilters();
},

downloadexcel() {
  this.excelLoading = true;
  const payload = {
    dateRange: this.dateRange,
    uploadDateRange: this.uploadDateRange,
    selectedTrunk: this.selectedTrunk,
    selectedPool: this.selectedPool,
    callsRange: this.callsRange,
    selectedFileNames: this.selectedFileNames,
    sortBy: this.options.sortBy,
    sortDesc: this.options.sortDesc,
  };

  this.$http.axios.post('/statistics/carousel-excel', payload, { responseType: 'blob' })
  .then((response) => {
    this.excelLoading = false;
    const fileURL = window.URL.createObjectURL(new Blob([response.data]))
    const fileLink = document.createElement('a')
    fileLink.href = fileURL
    fileLink.setAttribute('download', 'carousel_numbers.xlsx')
    document.body.appendChild(fileLink)
    fileLink.click()
    fileLink.remove()
  })
  .catch((error) => {
    this.excelLoading = false;
    console.error("Ошибка при скачивании Excel:", error)
    alert("Не удалось скачать Excel!")
  })
},

    uploadExcel() {
      try {
        if (!this.uploadFile) {
          alert("Пожалуйста, выберите Excel-файл!");
          return;
        }
        if (!this.uploadTrunk) {
          alert("Пожалуйста, выберите транк!");
          return;
        }
        if (!this.uploadPool) {
          alert("Пожалуйста, выберите пул!");
          return;
        }

        const formData = new FormData();
        formData.append('excelFile', this.uploadFile);
        formData.append('trunk', this.uploadTrunk);
        formData.append('pool', this.uploadPool);
        formData.append('tag', this.uploadTag);

        this.$http.post('/statistics/uploadExcel', formData,
          (resp) => {
            console.log(resp)
            alert("Номера успешно загружены!");
            this.uploadDialogOpen = false;
            this.uploadFile = null;
            this.uploadTrunk = '';
            this.uploadPool = '';
            this.uploadTag = '';
            this.applyFilters();
          },
          (err) => {
            console.error("Ошибка при загрузке:", err);
            alert("Ошибка при загрузке!");
          }
        );
      } catch (err) {
        console.error("uploadExcel error:", err);
        alert("Ошибка:"+err.message);
      }
    }
  }
};
</script>

<style scoped>
.my-5 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.pa-5 {
  padding: 2rem !important;
}
.filters-header {
  display: flex;
  align-items: center;
}
.my-date-input {
  height: 48px !important; 
  line-height: 48px !important;
  display: flex !important;
  align-items: center !important;
  box-sizing: border-box; 
  width: 100%;
  font-size: 14px;
  text-align: left;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  padding: 0 8px;
  color: #555;
  cursor: pointer;
}
.filter-label {
  display: block;
  font-weight: 600;
  margin-bottom: 6px;
  color: #666;
}
.v-card {
  border-radius: 8px;
}
.v-divider {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.carousel-numbers-table {
  font-size: 14px;
  background-color: #fff;
}
.carousel-numbers-table .v-data-table__wrapper table tr:nth-child(odd) {
  background-color: #fafafa;
}
.carousel-numbers-table .v-data-table__wrapper table tr:hover:not(.v-data-table__expanded__content) {
  background-color: #f0f0f0;
}
.carousel-numbers-table .v-data-footer {
  border-top: 1px solid #e0e0e0;
}
.carousel-numbers-table thead th {
  background-color: #f5f5f5;
  font-weight: 600;
  color: #444;
  text-transform: none;
  vertical-align: middle;
}
.carousel-numbers-table td {
  vertical-align: middle;
}
.same-height .v-text-field.v-text-field--outlined.v-text-field--dense,
.same-height .v-select.v-select--outlined.v-select--dense {
  height: 48px !important; 
  
  display: flex !important;
  align-items: center !important;
}

.same-height .v-text-field.v-text-field--outlined.v-text-field--dense .v-label,
.same-height .v-select.v-select--outlined.v-select--dense .v-label {
  top: 15px !important;
  transform: translateY(-50%) scale(0.75) !important;
}

::v-deep .my-override.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 48px !important;
  line-height: 48px !important;
  display: flex !important;
  align-items: center !important;
}
.same-height .v-label {
  top: 14px !important;
  transform: translateY(-50%) scale(0.75) !important;
}
</style>
